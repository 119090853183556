import React, {Component} from "react"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import ArrowForwardIcon from "@material-ui/icons/Forward"
import WaveSvg from "../assets/images/Wave.svg"
import ArrowForwardThinIcon from '@material-ui/icons/ArrowForward'
import BreadCrumbs from "../components/bread-crumbs";
import SEO from "../components/seo"
import "./who-its-for.scss"

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

class WhoIsItFor extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        window.analytics.page("Who is it For")
    }

    render() {
        return (
            <Layout>
                <SEO title="Who is it for"/>
                <BreadCrumbs currentPage={"Who it's for"} breadCrumbsList={breadCrumbsList}/>
                <div className="common-detailed-header">
                    <div className="common-detailed-inner">
                        <div className="common-detailed-pattern-content">
                            <h2 className="common-detailed-main-head">Who is
                                Confidant for?</h2>
                            <h2 className="common-detailed-second-head">Confidant
                                is for anyone who suspects
                                that life could be better.</h2>
                            <p className="common-detailed-para">Our diverse
                                array of providers are skilled in supporting
                                people to achieve their goals, control their
                                worries, and begin to thrive. We support mental
                                health and specialize in drug
                                and alcohol use. </p>
                            <Button
                                id="submit-email"
                                className="blue-arrow-btn"
                                href={"/get-started"}
                            >
                                Get Started <ArrowForwardIcon/>
                            </Button>

                        </div>
                        <div className="common-detailed-image-side">
                            <img className="common-detailed-hero-img who-image"
                                 style={{objectFit: 'contain'}}
                                 src={require(
                                     '../assets/images/who-its-for.png')}
                                 alt="group-img"/>
                        </div>
                    </div>
                </div>

                <div className="common-wave-wrapper"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <img className="confetti-bg"
                         src={require('../assets/images/line-pattern.svg')}
                         alt="Pattern"/>
                    <div className="wave-bg">
                        <div className="common-wave-inner">
                            <h2 className="common-wave-heading">People have come
                                to us for things like this...</h2>
                            <div className="problem-list">
                                <div className="problem-list-inner">
                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/snooze-icon.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Perpetually
                                            hitting ‘snooze’ because last night
                                            got too wild... again.</p>
                                    </div>

                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/dreaming-icon.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Waking up
                                            the next morning and analyzing every
                                            move they made.</p>
                                    </div>

                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/question-icon.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Dreading the
                                            question, “How is [insert family
                                            member name] doing?”</p>
                                    </div>

                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/broken-heart.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Watching
                                            quality relationships slip away from
                                            them.</p>
                                    </div>

                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/wine-icon.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Boredom and
                                            booze have become one in the
                                            same.</p>
                                    </div>

                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/spy-icon.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Suspicion
                                            that maybe they’re self-medicating
                                            for something deeper.</p>
                                    </div>
                                </div>
                            </div>
                            <img className="sad-image"
                                 src={require(
                                     '../assets/images/sad-illustration.svg')}
                                 alt="Sad illustration"/>
                            <h2 className="common-wave-heading">They come to us
                                feeling...</h2>
                            <div className="problem-list">
                                <div className="problem-list-inner">
                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/sad-icon.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Like they
                                            aren’t being true to themselves.</p>
                                    </div>

                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/sad-icon.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Embarrassed
                                            about their choices or the
                                            consequences.</p>
                                    </div>

                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/sad-icon.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Like they’ve
                                            lost some of their confidence.</p>
                                    </div>

                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/sad-icon.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Disconnected
                                            or alone, even in a sea of familiar
                                            people.</p>
                                    </div>

                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/sad-icon.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Like they’re
                                            forever disappointing their
                                            family.</p>
                                    </div>

                                    <div className="single-problem">
                                        <img className="problem-icon"
                                             src={require(
                                                 '../assets/images/sad-icon.svg')}
                                             alt="Icon"/>
                                        <p className="problem-text">Unworthy of
                                            love and support.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="happy-wrapper">
                    <div className="happy-wrapper-inner">
                        <h2 className="happy-wrapper-heading">We help them build
                            the mindset, habits, and skills to <br/>thrive.
                            Imagine what life would look like <br/>
                            if you could just be you?</h2>
                        <img className="happy-image"
                             src={require(
                                 '../assets/images/happy-illustration.svg')}
                             alt="Happy illustration"/>
                    </div>
                </div>


                <div className="who-services-wrapper">
                    <div className="who-services-wave-inner">
                        <h2 className="who-services-wave-heading">Curious what
                            help looks like?</h2>
                        <p className="who-services-wave-description">We have a
                            wide range of options to fit your personal
                            situation</p>
                        <div className="wave-service-list">
                            <div className="single-wave-service-box">
                                <img className="new-feature-image"
                                     src={require(
                                         "../assets/images/provider-prescriber.svg")}
                                     alt="Providers & Prescriber Visual"/>
                                <Button href="/online-therapy/"
                                        className="new-blue-link"
                                        color="primary">
                                    Providers &
                                    Prescribers <ArrowForwardThinIcon/>
                                </Button>
                            </div>

                            <div className="single-wave-service-box">
                                <img className="new-feature-image"
                                     src={require(
                                         "../assets/images/free-support.svg")}
                                     alt="Support Groups Visual"/>
                                <Button href="/support-groups/"
                                        className="new-blue-link"
                                        color="primary">
                                    Support Groups <ArrowForwardThinIcon/>
                                </Button>
                            </div>

                            <div className="single-wave-service-box">
                                <img className="new-feature-image"
                                     src={require(
                                         "../assets/images/self-directed.svg")}
                                     alt="Self-directed Courses Visual"/>
                                <Button href="/how-to-guides/"
                                        className="new-blue-link"
                                        color="primary">
                                    Self-directed
                                    Courses <ArrowForwardThinIcon/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        )
    }

}

export default WhoIsItFor


